body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* width: calc(100vw-60px); */
  /* overflow-x: hidden; */
  /* overflow-x: hidden; */
}

body.dark-mode {
  --scrollbar-bg: #555;
  --scrollbar-thumb-bg: #333;
  --scrollbar-track-bg: #000;
}

/* light-scrollbar.css */
body.light-mode {
  --scrollbar-bg: #ddd;
  --scrollbar-thumb-bg: #ccc;
  --scrollbar-track-bg: #eee;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track-bg);
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-bg);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.password-forget-form {
  margin-top: 60px;
}

.form-container {
  max-width: 400px;
  margin: auto;
  padding-top: 30px;
}

.login-form-button {
  width: 100%;
  margin-top: 10px;
}

.button-row {
  margin-bottom: 20px;
}

/* .large-font textarea{
  font-size: x-large !important;
}

.small-font textarea{
  font-size: medium !important;
}

*/

/* Glowing effect */
/*
.large-font textarea, .small-font textarea{
  color: limegreen;
  text-shadow: 0 0 1px limegreen;
} 
*/

.chat-list {
  margin: 2px 0 0 2px;
}

.graph-container {
  min-height: 600px;
  overflow-y: auto;
  z-index: 5;
}

.g6-edge-tooltip {
  padding: 10px 6px;
  /* color: #444; */
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 12px;
  position: absolute;
}

.g6-tooltip {
  padding: 10px 6px;
  /* color: #444; */
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 12px;
  position: absolute;
}

.bot-event-name-input {
  font-size: 12px;
}

.bot-date-time-editing-picker > .ant-picker-input input {
  font-size: 12px;
}

.date-time-editing-picker > .ant-picker-input input {
  font-size: 10px;
}

.react-markdown ul {
  /* margin-top: 1em;
  margin-bottom: 1em; */
  /* list-style: disc outside none; */
  padding-inline-start: 20px;
}

.ant-tour {
  position: absolute; /* Positioning relative to the next relative/absolute positioned ancestor, which is <body> */
  top: 20px;
  left: 50%;
  /* transform: translateX(-50%); */
  max-width: calc(100vw - 40px);
}

.comment-card {
  padding-top: 20px;
  padding-left: 20px;
  margin-bottom: 20px;
  box-shadow: none !important;
  border: none;
  border-radius: 0px;
  background-color: rgba(255, 255, 255, 0);
}

.comment-card .ant-card-body {
  margin: 0px;
  padding: 0px;
}

.ant-comment-nested {
  margin-left: 20px !important;
}

.ant-comment-nested .ant-comment-inner {
  padding-top: 0px;
}

/* style={{ margin: 0, padding: 0, boxShadow: "none", ...style }} */

#customTooltip {
  position: absolute;
  display: none;
  pointer-events: none;
  z-index: 100;
  background-color: rgba(251, 251, 251, 0.9);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  color: #222;
}

#customTooltip ul {
  padding-left: 0px;
  list-style-position: inside;
}

#customTooltip li {
  padding-left: 2px;
  margin-top: 8px;
  white-space: nowrap; /* Prevent wrapping of text */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis if text is too long */
}

.ant-tour-content {
  margin: 0 !important;
}

/* .span-arc-input-number {
  padding-left: 6px;
}

.span-arc-input-number .ant-input-number-suffix {
  margin-inline-end: 4px;
  margin-inline-start: 2px;
} */

@keyframes shine {
  0% {
    background-position: 150%;
  }
  100% {
    background-position: -50%;
  }
}

.shine-effect {
  animation: shine 4s linear infinite;
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0) 100%
    )
    rgba(36, 81, 117, 0.2);
  background-size: 200% 100%;
}

.landing-button-style {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s ease-out, box-shadow 0.45s ease-out;
}

.landing-button-style:hover {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(0, 169, 168, 0.75);
}

.banner-user {
  height: 440px;
}
.banner-user-elem {
  text-align: center;
  color: #fff;
  position: relative;
  overflow: hidden;
  display: flex; /* Using Flexbox */
  flex-direction: column; /* Children are stacked vertically */
  justify-content: center; /* Centers children vertically in the container */
  align-items: center; /* Centers children horizontally in the container */
  min-height: 100%; /* Ensures at least 100% height but can grow */
}

.banner-user-elem .banner-user-text {
  top: 40%;
}

.banner-anim-elem .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.block-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
